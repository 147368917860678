import React from 'react';

const Log = ({ items, format }) => (
  <ul className="list-group">
    {items.map((item, index) => (
      <li key={index} className="list-group-item">
        {format(item)}
      </li>
    ))}
  </ul>
);
export default Log;
