const invoke = (serviceName) => async (methodName, ...args) => {
  const response = await fetch('/api/rpc', {
    method: 'POST',
    body: JSON.stringify({ serviceName, methodName, arguments: args }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const { error, result } = await response.json();
  if (error) {
    throw new Error(error);
  }
  return result;
};

export default invoke;
