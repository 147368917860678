import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import invoke from '../rpc';

const invokeLogService = invoke('LogService');

const logLevels = {
  debug: 'secondary',
  info: 'success',
  warn: 'warning',
  error: 'danger',
  fatal: 'danger',
  panic: 'danger',
};

const Log = () => {
  let { gameRoundId } = useParams();
  gameRoundId = parseInt(gameRoundId, 10);
  const [logItems, setLogItems] = useState([]);
  useEffect(() => {
    invokeLogService('Search', gameRoundId)
      .then((results) => results.hits.hits.map(({ _source }) => ({ message: _source.message, ..._source.json })))
      .then(setLogItems, console.error);
  }, [gameRoundId]);
  return logItems
    .sort(({ time: time1 }, { time: time2 }) => time1.localeCompare(time2))
    .map(({ message, time, caller, level }, index) => (
      <div key={index} className={`card border-${logLevels[level]} mt-2`}>
        <div className={`card-header text-${logLevels[level]}`}>
          {new Date(time).toLocaleString()}.{new Date(time).getMilliseconds()} {caller}
        </div>
        <div className="card-body">
          <p className="card-text">{message}</p>
        </div>
      </div>
    ));
};

export default Log;
