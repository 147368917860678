import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import invoke from '../rpc';

const invokeGameService = invoke('GameService');

const Game = () => {
  const { gameId } = useParams();
  const [game, setGame] = useState();
  useEffect(() => {
    invokeGameService('GetGame', gameId).then(setGame);
  }, [gameId]);
  return (
    <div className="card mb-3">
      <h5 className="card-header text-white mb-3 bg-success">{gameId}</h5>
      <pre>
        <code>{game && JSON.stringify(game.config, null, 2)}</code>
      </pre>
    </div>
  );
};

export default Game;
