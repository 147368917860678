import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import invoke from '../rpc';

const invokeGameService = invoke('GameService');

const Games = () => {
  const [games, setGames] = useState([]);
  useEffect(() => {
    invokeGameService('GetGames').then(setGames);
  }, []);
  const suspend = (id, isSuspended) =>
    invokeGameService('SuspendGame', id, isSuspended).then(() =>
      setGames(
        games.map(({ gameId, ...game }) => (gameId === id ? { gameId, ...game, isSuspended } : { gameId, ...game }))
      )
    );
  return (
    <table className="table table-sm table-bordered table-hover table-striped">
      <caption>Games</caption>
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">gameId</th>
          <th scope="col">engine</th>
          <th scope="col">suspension</th>
        </tr>
      </thead>
      <tbody>
        {games.map(({ gameId, engine, isSuspended }, index) => (
          <tr key={gameId}>
            <td>{index}</td>
            <td>
              <Link className="nav-link" to={`/games/${gameId}`}>
                {gameId}
              </Link>
            </td>
            <td>{engine}</td>
            <td>
              <button className="btn btn-primary" onClick={() => suspend(gameId, !isSuspended)}>
                {isSuspended ? 'Unsuspend' : 'Suspend'}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Games;
