import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import invoke from '../rpc';
import { Rings } from 'react-loader-spinner';
import Dropdown from 'react-dropdown';
import { CSVLink } from "react-csv";
import getEnvironmentName from '../environmentName';


const invokeGameRoundHistoryService = invoke('GameRoundHistoryService');
const size = 300;

// Array.prototype.sum = function (prop) {
//   var total = 0
//   for (var i = 0, _len = this.length; i < _len; i++) {
//     total += this[i][prop]
//   }
//   return total
// }
let gameIdArr = ["All Games"]

// function dynamicSort(property) {
//   var sortOrder = 1;
//   if (property[0] === "-") {
//     sortOrder = -1;
//     property = property.substr(1);
//   }
//   return function (a, b) {
//     /* next line works with strings and numbers, 
//      * and you may want to customize it to your needs
//      */
//     var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
//     return result * sortOrder;
//   }
// }


const invokeGameService = invoke('GameService');

const GameRounds = () => {
  const [visible, setVisible] = useState(false);
  const [unsortedGameRounds, setunsortedGameRounds] = useState([]);
  const [gameRounds, setGameRounds] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [downloadableRounds, setDownloadableRounds] = useState([]);
  const [linkVisible, setLinkVisible] = useState("none");
  const [gameID, setGameId] = useState(JSON.parse(window.localStorage.getItem('GameRoundHistoryGameId')) || null);
  const [games, setGames] = useState(JSON.parse(window.localStorage.getItem('GameSearchDetails')) || []);
  const [currency, setCurrency] = useState(JSON.parse(window.localStorage.getItem('GameRoundHistoryCurrency')) || null);
  const [subPartnerId, setSubPartnerId] = useState(null);
  const [totalStake, setTotalStake] = useState(0);
  const [totalReturn, setTotalReturn] = useState(0);
  const [totalGGR, setTotalGGR] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [currencies, setCurrencies] = useState(["All Currencies"]);
  const [subPartnerIds, setSubPartnerIds] = useState(["All SubPartnerIds"]);
  const [currencyBucket, setCurrencyBucket] = useState([]);
  const [lastTimeStamp, setLastTimeStamp] = useState("");
  const [newFetch, setNewFetch] = useState(true);
  const [environmentName, setEnvironmentName] = useState("localhost");
  let subPartnerUrl = window.location.hostname.split(".")[0] !== 'assets' && window.location.hostname !== 'localhost' ? window.location.hostname.split(".")[0] : '';
  if (window.location.hostname !== "localhost" && environmentName !== getEnvironmentName(window.location.hostname).toUpperCase()) {
    setEnvironmentName(getEnvironmentName(window.location.hostname).toUpperCase());
  }

  gameIdArr = ["All Games"];
  games.map(({ gameId }, index) => (gameIdArr.push(gameId)));
  const gameRoundHistoryServiceParams = JSON.parse(window.localStorage.getItem('GameRoundHistoryServiceParams'));
  let [tsCreatedFrom, tsCreatedTo, playerId, gameRoundId, gameId] = Array.from({ length: 5 }, useRef);
  (gameRoundHistoryServiceParams != null) && ([tsCreatedFrom, tsCreatedTo, playerId, gameRoundId, gameId] = gameRoundHistoryServiceParams);
  const onCurrencyChange = option => {
    setCurrency(option.value === "All Currencies" ? null : option.value);
  }
  const onSubPartnerIdChange = option => {
    setSubPartnerId(option.value === "All SubPartnerIds" ? null : option.value);
  }
  const getGMTDateTimeString = (localDateTimeString, currentTime = false) => {
    let localDateObject = null;
    if (currentTime) {
      localDateObject = new Date(localDateTimeString);
      let date = new Date();
      localDateObject.setHours(date.getHours(), date.getMinutes(), date.getSeconds());
    } else {
      localDateObject = new Date(localDateTimeString);
      localDateObject.setHours(0, 0, 0);
    }

    let day = localDateObject.getUTCDate();
    day = day < 10 ? `0${day}` : day;
    let month = (localDateObject.getUTCMonth() + 1);
    month = month < 10 ? `0${month}` : month;
    const year = localDateObject.getUTCFullYear();
    let hours = localDateObject.getUTCHours();
    hours = hours < 10 ? `0${hours}` : hours;
    let minutes = localDateObject.getUTCMinutes();
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    let seconds = localDateObject.getUTCSeconds();
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    let milliseconds = localDateObject.getUTCMilliseconds();
    milliseconds = milliseconds < 10 ? `00${milliseconds}` : milliseconds < 100 ? `0${milliseconds}` : milliseconds;
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }

  function onchange(option) {
    setGameId(option.value === "All Games" ? null : option.value);
  }
  useEffect(() => {
    invokeGameService('GetGames').then((result) => {
      setGames(result);
      gameIdArr = ["All Games"]
      result.map(({ gameId }, index) => (gameIdArr.push(gameId)));
      window.localStorage.setItem('GameSearchDetails', JSON.stringify(result));
    })

  }, []);

  const findAggregatedGGR = () => {
    return new Promise((resolve) => {
      const gameRoundSearchParams = JSON.parse(window.localStorage.getItem('GameRoundHistoryServiceParams'));
      let Filter = {};
      if (currency && currency !== "Blank" && subPartnerId && subPartnerId !== 'Blank') {
        Filter = {
          filter: [
            {
              terms: {
                subpartner_id: [subPartnerId.toUpperCase(), subPartnerId.toLowerCase()]
              }
            },
            {
              terms: {
                currency: [currency.toUpperCase(), currency.toLowerCase()]
              }
            }
          ]
        }
      }
      else if (currency && currency !== "Blank") {
        Filter = {
          filter: [
            {
              terms: {
                currency: [currency.toUpperCase(), currency.toLowerCase()]
              }
            }
          ]
        }
      }
      else if (subPartnerId && subPartnerId !== 'Blank') {
        Filter = {
          filter: [
            {
              terms: {
                subpartner_id: [subPartnerId.toUpperCase(), subPartnerId.toLowerCase()]
              }
            }
          ]
        }
      }
      invokeGameRoundHistoryService('SearchGameRoundHistory', {
        _source: ["totalStake", "totalReturn"],
        query: {
          bool: {
            must: [
              ...((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value) || (tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value)
                ? [
                  {
                    range: {
                      "@timestamp": {
                        ...((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value) ? { gte: getGMTDateTimeString((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value)) } : { gte: getGMTDateTimeString(new Date().setHours(0, 0, 0)) }),
                        ...((tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value) ? { lte: getGMTDateTimeString((tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value), false) } : { lte: getGMTDateTimeString(new Date().toString(), true) }),
                      },
                    },
                  },
                ]
                : [
                  {
                    range: {
                      "@timestamp": {
                        gte: getGMTDateTimeString(new Date().setHours(0, 0, 0)),
                        lte: getGMTDateTimeString(new Date().toString(), true)
                      }
                    }
                  }
                ]),
              ...((gameRoundId?.current?.value || gameRoundSearchParams[3]?.current?.value) ? [{ term: { _id: +(gameRoundId?.current?.value || gameRoundSearchParams[3]?.current?.value) } }] : []),
              ...(playerId?.current?.value || gameRoundSearchParams[2]?.current?.value ? [{ term: { playerId: playerId?.current?.value || gameRoundSearchParams[2]?.current?.value } }] : []),
              ...((gameID === "Blank" ? "" : gameID) ? [{
                wildcard: {
                  gameId: {
                    value: `*${gameID.split("-")[gameID.split("-").length - 1]}*`,
                    case_insensitive: true
                  }
                }
              }] : []),
              { term: { isPlayForFun: false } }
            ],
            ...Filter

          },
        },
        "aggs": {
          "totalStake":
          {
            "sum":
              { "field": "totalStake" }
          },
          "totalReturn": {
            "sum":
              { "field": "totalReturn" }
          },
          "docsCount": {
            "value_count": {
              "field": "totalStake"
            }
          },
          "uniqueCurrencies": {
            "terms": {
              "field": "currency"
            }
          },
          "uniqueSubPartnerIds": {
            "terms": {
              "field": "subpartner_id"
            }
          }
        }
      }).then(({ aggregations }) => {
        setTotalStake(aggregations?.totalStake?.value ? aggregations.totalStake.value : 0)
        setTotalReturn(aggregations?.totalReturn?.value ? aggregations.totalReturn.value : 0)
        setTotalGGR((aggregations?.totalStake?.value ? aggregations.totalStake.value : 0) - (aggregations?.totalReturn?.value ? aggregations.totalReturn.value : 0));
        setTotalDocs(aggregations?.docsCount?.value ? aggregations.docsCount.value : 0);
        const buckets = (aggregations?.uniqueCurrencies?.buckets) ? aggregations.uniqueCurrencies.buckets : [];
        const subPartnerIdBuckets = (aggregations?.uniqueSubPartnerIds?.buckets) ? aggregations.uniqueSubPartnerIds.buckets : [];
        if (currency == null) {
          setCurrencies(["All Currencies", ...buckets.map(currData => currData.key.toUpperCase())]);
        }
        if (subPartnerId == null) {
          setSubPartnerIds(["All SubPartnerIds", ...subPartnerIdBuckets.map(subPartnerIdData => subPartnerIdData.key.toUpperCase())]);
        }
        let bucketsByCurrency = buckets.map(currData => {
          let Filter = {};
          if (currData.key && currData.key !== "Blank" && subPartnerId && subPartnerId !== 'Blank') {
            Filter = {
              filter: [
                {
                  terms: {
                    subpartner_id: [subPartnerId.toUpperCase(), subPartnerId.toLowerCase()]
                  }
                },
                {
                  terms: {
                    currency: [currData.key.toUpperCase(), currData.key.toLowerCase()]
                  }
                }
              ]
            }
          }
          else if (currData.key && currData.key !== "Blank") {
            Filter = {
              filter: [
                {
                  terms: {
                    currency: [currData.key.toUpperCase(), currData.key.toLowerCase()]
                  }
                }
              ]
            }
          }
          else if (subPartnerId && subPartnerId !== 'Blank') {
            Filter = {
              filter: [
                {
                  terms: {
                    subpartner_id: [subPartnerId.toUpperCase(), subPartnerId.toLowerCase()]
                  }
                }
              ]
            }
          }
          return invokeGameRoundHistoryService('SearchGameRoundHistory', {
            _source: ["totalStake", "totalReturn"],
            query: {
              bool: {
                must: [
                  ...((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value) || (tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value)
                    ? [
                      {
                        range: {
                          "@timestamp": {
                            ...((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value) ? { gte: getGMTDateTimeString((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value)) } : { gte: getGMTDateTimeString(new Date().setHours(0, 0, 0)) }),
                            ...((tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value) ? { lte: getGMTDateTimeString((tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value), false) } : { lte: getGMTDateTimeString(new Date().toString(), true) }),
                          },
                        },
                      },
                    ]
                    : [
                      {
                        range: {
                          "@timestamp": {
                            gte: getGMTDateTimeString(new Date().setHours(0, 0, 0)),
                            lte: getGMTDateTimeString(new Date().toString(), true)
                          }
                        }
                      }
                    ]),
                  ...((gameRoundId?.current?.value || gameRoundSearchParams[3]?.current?.value) ? [{ term: { _id: +(gameRoundId?.current?.value || gameRoundSearchParams[3]?.current?.value) } }] : []),
                  ...(playerId?.current?.value || gameRoundSearchParams[2]?.current?.value ? [{ term: { playerId: playerId?.current?.value || gameRoundSearchParams[2]?.current?.value } }] : []),
                  ...((gameID === "Blank" ? "" : gameID) ? [{
                    wildcard: {
                      gameId: {
                        value: `*${gameID.split("-")[gameID.split("-").length - 1]}*`,
                        case_insensitive: true
                      }
                    }
                  }] : []),
                  { term: { isPlayForFun: false } }
                ],
                ...Filter

              },
            },
            "aggs": {
              "totalStake":
              {
                "sum":
                  { "field": "totalStake" }
              },
              "totalReturn": {
                "sum":
                  { "field": "totalReturn" }
              },
              "uniqueCurrencies": {
                "terms": {
                  "field": "currency"
                }
              }
            }
          });
        });
        Promise.all(bucketsByCurrency).then(bucketData => {
          const aggregatedData = bucketData.map(data => {
            const bucket = data.aggregations.uniqueCurrencies.buckets[0];
            return {
              currency: bucket.key,
              doc_count: bucket.doc_count,
              totalStake: data?.aggregations?.totalStake?.value ? data.aggregations.totalStake.value : 0,
              totalReturn: data?.aggregations?.totalReturn?.value ? data.aggregations.totalReturn.value : 0
            }
          });
          setCurrencyBucket(aggregatedData);
          resolve();
        })
      }).catch(() => {
        resolve();
      })
    })
  }
  const search = (isNew) => {
    if (subPartnerUrl.length > 0) {
      setSubPartnerId(subPartnerUrl);
    }
    setVisible(true);
    if (isNew) {
      setNewFetch(true);
      setLinkVisible("none");
    }
    const gameRoundSearchParams = JSON.parse(window.localStorage.getItem('GameRoundHistoryServiceParams'));

    window.localStorage.setItem('GameRoundHistoryGameId', JSON.stringify(gameID));
    window.localStorage.setItem('GameRoundHistoryCurrency', JSON.stringify(currency));
    window.localStorage.setItem('GameRoundHistoryServiceParams', JSON.stringify([
      {
        current: {
          value: isNew ? tsCreatedFrom.current.value : gameRoundSearchParams[0]?.current.value
        }
      },
      {
        current: {
          value: isNew ? tsCreatedTo.current.value : gameRoundSearchParams[1]?.current.value
        }
      },
      {
        current: {
          value: isNew ? playerId.current.value : gameRoundSearchParams[2]?.current?.value
        }
      },
      {
        current: {
          value: isNew ? gameRoundId.current.value : gameRoundSearchParams[3]?.current?.value
        }
      },
      {
        current: {
          value: isNew ? gameId.current.value : gameRoundSearchParams[4]?.current?.value
        }
      }
    ]))
    let Filter = {};
    if (currency && currency !== "Blank" && subPartnerId && subPartnerId !== 'Blank') {
      Filter = {
        filter: [
          {
            terms: {
              subpartner_id: [subPartnerId.toUpperCase(), subPartnerId.toLowerCase()]
            }
          },
          {
            terms: {
              currency: [currency.toUpperCase(), currency.toLowerCase()]
            }
          }
        ]
      }
    }
    else if (currency && currency !== "Blank") {
      Filter = {
        filter: [
          {
            terms: {
              currency: [currency.toUpperCase(), currency.toLowerCase()]
            }
          }
        ]
      }
    }
    else if (subPartnerId && subPartnerId !== 'Blank') {
      Filter = {
        filter: [
          {
            terms: {
              subpartner_id: [subPartnerId.toUpperCase(), subPartnerId.toLowerCase()]
            }
          }
        ]
      }
    }
    let search_after = {};
    if (!isNew && unsortedGameRounds.length > 0 && lastTimeStamp.length > 0) {
      search_after.search_after = [];
      search_after.search_after.push(lastTimeStamp);
    }

    isNew && findAggregatedGGR()
    invokeGameRoundHistoryService('SearchGameRoundHistory', {
      size,
      _source: {
        excludes: ['commands'],
      },
      query: {
        bool: {
          must: [
            ...((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value) || (tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value)
              ? [
                {
                  range: {
                    "@timestamp": {
                      ...((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value) ? { gte: getGMTDateTimeString((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value)) } : { gte: getGMTDateTimeString(new Date().setHours(0, 0, 0)) }),
                      ...((tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value) ? { lte: getGMTDateTimeString((tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value), false) } : { lte: getGMTDateTimeString(new Date().toString(), true) }),
                    },
                  },
                },
              ]
              : [
                {
                  range: {
                    "@timestamp": {
                      gte: getGMTDateTimeString(new Date().setHours(0, 0, 0)),
                      lte: getGMTDateTimeString(new Date().toString(), true)
                    }
                  }
                }
              ]),
            ...((gameRoundId?.current?.value || gameRoundSearchParams[3]?.current?.value) ? [{ term: { _id: +(gameRoundId?.current?.value || gameRoundSearchParams[3]?.current?.value) } }] : []),
            ...(playerId?.current?.value || gameRoundSearchParams[2]?.current?.value ? [{ term: { playerId: playerId?.current?.value || gameRoundSearchParams[2]?.current?.value } }] : []),
            ...((gameID === "Blank" ? "" : gameID) ? [{
              wildcard: {
                gameId: {
                  value: `*${gameID.split("-")[gameID.split("-").length - 1]}*`,
                  case_insensitive: true
                }
              }
            }] : []),
            { term: { isPlayForFun: false } }
          ],
          ...Filter

        },
      },
      sort: [
        { "tsCompleted": { "order": "asc", "format": "strict_date_optional_time_nanos", "numeric_type": "date_nanos" } }
      ],
      ...search_after
    })
      .then(({ hits }) => {

        if (hits.hits.length > 0) {
          const sortAvailable = hits.hits[hits.hits.length - 1]["sort"] !== undefined;
          const sortObjExists = Array.isArray(hits.hits[hits.hits.length - 1]["sort"]) && (hits.hits[hits.hits.length - 1]["sort"].length > 0)
          if (sortAvailable && sortObjExists) {
            setLastTimeStamp(hits.hits[hits.hits.length - 1].sort[0])
          }
        }
        return hits.hits.map(({ _source }) => _source);
      })
      .then((moreGameRounds) => {
        const unSortedGameRounds = isNew ? [...moreGameRounds] : [...gameRounds, ...moreGameRounds];
        setunsortedGameRounds(unSortedGameRounds);
        const sortedGameRounds = unSortedGameRounds.sort(function (a, b) { return a.gameRoundId - b.gameRoundId });
        setGameRounds(sortedGameRounds);
        setVisible(false);
        // window.localStorage.setItem('GameRoundHistory', JSON.stringify([...(isNew ? [] : gameRounds), ...moreGameRounds]))
        setHasMore(moreGameRounds.length > 0);
      });

  };
  const formatDataForCSV = gameRounds => {
    const formattedGameRounds = [];
    gameRounds.forEach(gameRound => {
      const formattedGameRound = {};
      const localDate = new Date(gameRound.tsCompleted);
      let day = localDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let month = (localDate.getMonth() + 1);
      month = month < 10 ? `0${month}` : month;
      const year = localDate.getFullYear();
      formattedGameRound['Date'] = `${day}-${month}-${year}`;
      formattedGameRound['Game Round Id'] = gameRound.gameRoundId;
      formattedGameRound['Player Id'] = gameRound.playerId;
      formattedGameRound['Game Id'] = gameRound.gameId;
      formattedGameRound['Sub Partner Id'] = gameRound.subpartner_id ? gameRound.subpartner_id : environmentName;
      formattedGameRound['Mode'] = gameRound.isPlayForFun ? 'fun' : 'real';
      formattedGameRound['Currency'] = gameRound.currency;
      formattedGameRound['Start Date'] = new Date(gameRound.tsCreated).toLocaleString();
      formattedGameRound['End Date'] = new Date(gameRound.tsCompleted).toLocaleString();
      formattedGameRound['STAKE'] = gameRound.totalStake;
      formattedGameRound['WIN'] = gameRound.totalReturn;
      formattedGameRound['GGR'] = gameRound.totalStake - gameRound.totalReturn;
      formattedGameRounds.push(formattedGameRound);
    });
    return formattedGameRounds;
  }
  const fetchReportsForDownload = (isNew, downloadableRoundsLatest, lastTimeStampFetch) => {
    setVisible(true);
    isNew && setLinkVisible("none");
    const gameRoundSearchParams = JSON.parse(window.localStorage.getItem('GameRoundHistoryServiceParams'));

    window.localStorage.setItem('GameRoundHistoryGameId', JSON.stringify(gameID));
    window.localStorage.setItem('GameRoundHistoryCurrency', JSON.stringify(currency));
    window.localStorage.setItem('GameRoundHistoryServiceParams', JSON.stringify([
      {
        current: {
          value: isNew ? tsCreatedFrom.current.value : gameRoundSearchParams[0]?.current.value
        }
      },
      {
        current: {
          value: isNew ? tsCreatedTo.current.value : gameRoundSearchParams[1]?.current.value
        }
      },
      {
        current: {
          value: isNew ? playerId.current.value : gameRoundSearchParams[2]?.current?.value
        }
      },
      {
        current: {
          value: isNew ? gameRoundId.current.value : gameRoundSearchParams[3]?.current?.value
        }
      },
      {
        current: {
          value: isNew ? gameId.current.value : gameRoundSearchParams[4]?.current?.value
        }
      }
    ]))
    let Filter = {};
    if (currency && currency !== "Blank" && subPartnerId && subPartnerId !== 'Blank') {
      Filter = {
        filter: [
          {
            terms: {
              subpartner_id: [subPartnerId.toUpperCase(), subPartnerId.toLowerCase()]
            }
          },
          {
            terms: {
              currency: [currency.toUpperCase(), currency.toLowerCase()]
            }
          }
        ]
      }
    }
    else if (currency && currency !== "Blank") {
      Filter = {
        filter: [
          {
            terms: {
              currency: [currency.toUpperCase(), currency.toLowerCase()]
            }
          }
        ]
      }
    }
    else if (subPartnerId && subPartnerId !== 'Blank') {
      Filter = {
        filter: [
          {
            terms: {
              subpartner_id: [subPartnerId.toUpperCase(), subPartnerId.toLowerCase()]
            }
          }
        ]
      }
    }
    let search_after = {};
    if (!isNew && lastTimeStampFetch) {
      search_after.search_after = [];
      search_after.search_after.push(lastTimeStampFetch);
    }

    invokeGameRoundHistoryService('SearchGameRoundHistory', {
      size: 10000,
      _source: {
        excludes: ['commands'],
      },
      query: {
        bool: {
          must: [
            ...((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value) || (tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value)
              ? [
                {
                  range: {
                    "@timestamp": {
                      ...((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value) ? { gte: getGMTDateTimeString((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value)) } : { gte: getGMTDateTimeString(new Date().setHours(0, 0, 0)) }),
                      ...((tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value) ? { lte: getGMTDateTimeString((tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value), false) } : { lte: getGMTDateTimeString(new Date().toString(), true) }),
                    },
                  },
                },
              ]
              : [
                {
                  range: {
                    "@timestamp": {
                      gte: getGMTDateTimeString(new Date().setHours(0, 0, 0)),
                      lte: getGMTDateTimeString(new Date().toString(), true)
                    }
                  }
                }
              ]),
            ...((gameRoundId?.current?.value || gameRoundSearchParams[3]?.current?.value) ? [{ term: { _id: +(gameRoundId?.current?.value || gameRoundSearchParams[3]?.current?.value) } }] : []),
            ...(playerId?.current?.value || gameRoundSearchParams[2]?.current?.value ? [{ term: { playerId: playerId?.current?.value || gameRoundSearchParams[2]?.current?.value } }] : []),
            ...((gameID === "Blank" ? "" : gameID) ? [{
              wildcard: {
                gameId: {
                  value: `*${gameID.split("-")[gameID.split("-").length - 1]}*`,
                  case_insensitive: true
                }
              }
            }] : []),
            { term: { isPlayForFun: false } }
          ],
          ...Filter

        },
      },
      sort: [
        { "tsCompleted": { "order": "asc", "format": "strict_date_optional_time_nanos", "numeric_type": "date_nanos" } }
      ],
      ...search_after
    })
      .then(({ hits }) => {
        let lastTimeStampFetch1;

        if (hits.hits.length > 0) {
          const sortAvailable = hits.hits[hits.hits.length - 1]["sort"] !== undefined;
          const sortObjExists = Array.isArray(hits.hits[hits.hits.length - 1]["sort"]) && (hits.hits[hits.hits.length - 1]["sort"].length > 0)
          if (sortAvailable && sortObjExists) {
            lastTimeStampFetch1 = hits.hits[hits.hits.length - 1].sort[0]
          }
        }
        return { lastTimeStampFetchLatest: lastTimeStampFetch1, moreGameRounds: hits.hits.map(({ _source }) => _source) };
      })
      .then((data) => {
        const { moreGameRounds, lastTimeStampFetchLatest } = data;
        const unSortedGameRounds = isNew ? [...moreGameRounds] : [...downloadableRoundsLatest, ...moreGameRounds];
        // setunsortedDownloadableGameRounds(unSortedGameRounds);
        const sortedGameRounds = unSortedGameRounds.sort(function (a, b) { return a.gameRoundId - b.gameRoundId });
        // setDownloadableRounds(sortedGameRounds);
        if (moreGameRounds.length <= 0) {
          setLinkVisible("block");
          setDownloadableRounds(sortedGameRounds)
          setVisible(false);
          setNewFetch(true);
        } else {
          setNewFetch(false);
          fetchReportsForDownload(false, sortedGameRounds, lastTimeStampFetchLatest);
        }
      });
  }
  return (
    <>
      <form className="formLabel">
        <div className="form-group row mt-3">
          <div className="col-sm-6">
            <input className="form-control" defaultValue={tsCreatedFrom.current && tsCreatedFrom.current.value} type="date" ref={tsCreatedFrom} />
          </div>
          <div className="col-sm-6">
            <input className="form-control" defaultValue={tsCreatedTo.current && tsCreatedTo.current.value} type="date" ref={tsCreatedTo} />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-2">
            <input className="form-control" defaultValue={gameRoundId.current && gameRoundId.current.value} placeholder="gameRoundId" type="number" ref={gameRoundId} />
          </div>
          <div className="col-sm-2">
            <input className="form-control" defaultValue={playerId.current && playerId.current.value} placeholder="playerId" type="text" ref={playerId} />
          </div>
          {subPartnerUrl.length <= 0 &&
            <div className="col-sm-2">
              <Dropdown value={subPartnerId == null ? "All SubPartnerIds" : subPartnerId} className="form-control" onChange={onSubPartnerIdChange} options={subPartnerIds} placeholder="Select SubPartnerId" type="text" />
            </div>
          }
          {subPartnerUrl.length > 0 ?
            <div className="col-sm-3">
              <Dropdown value={gameID == null ? "All Games" : gameID} className="form-control" onChange={onchange} options={gameIdArr} placeholder="Select GameID" type="text" ref={gameId} />
            </div> :
            <div className="col-sm-2">
              <Dropdown value={gameID == null ? "All Games" : gameID} className="form-control" onChange={onchange} options={gameIdArr} placeholder="Select GameID" type="text" ref={gameId} />
            </div>
          }
          {subPartnerUrl.length > 0 ?
            <div className="col-sm-3">
              <Dropdown value={currency == null ? "All Currencies" : currency} className="form-control" onChange={onCurrencyChange} options={currencies} placeholder="Select Currency" type="text" />
            </div> :
            <div className="col-sm-2">
              <Dropdown value={currency == null ? "All Currencies" : currency} className="form-control" onChange={onCurrencyChange} options={currencies} placeholder="Select Currency" type="text" />
            </div>
          }
          <div className="col-sm-2">
            <button className="btn btn-primary btn-block" type="button" onClick={() => search(true)}>
              Search
            </button>
          </div>
        </div>
      </form>


      <div>
        <button className="btn btn-primary btn-block" type="button" onClick={() => fetchReportsForDownload(newFetch, [])}>
          Fetch Reports
        </button>
        <h1 className='dashHeader' style={{ display: linkVisible }} >Mini DashBoard <CSVLink filename={`Game Round History Report ${new Date().toLocaleString()}`} data={formatDataForCSV(downloadableRounds)}>Download Report</CSVLink></h1>


      </div>

      <div style={{ textAlign: 'center' }}>
        <div style={{ display: 'inline-block', textAlign: 'center' }}>
          <Rings height="100" width="100" color='#00BFFF' ariaLabel='loading' visible={visible} />
        </div>
      </div>

      <table className="table table-bordered table-hover table-striped">




        <thead>
          <tr>
            <td>totalbet</td>
            <td>totalwin</td>
            <td>totalGGR</td>
            <td>totalGames</td>
          </tr>
        </thead>


        <tbody>
          <tr>

            <td>{Intl.NumberFormat('en-IN').format(totalStake)}</td>
            <td>{Intl.NumberFormat('en-IN').format(totalReturn.toFixed(2))}</td>
            <td>{Intl.NumberFormat('en-IN').format(totalGGR.toFixed(2))}</td>
            <td>{Intl.NumberFormat('en-IN').format(totalDocs)}</td>

          </tr>
        </tbody>

      </table>

      <div>
        <h1 className='dashHeader'>Revenue Breakdown By Currency</h1>
      </div>
      <table className="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <td>currency</td>
            <td>totalbet</td>
            <td>totalwin</td>
            <td>totalGGR</td>
            <td>totalGames</td>
          </tr>
        </thead>


        <tbody>
          {
            currencyBucket.map(bucket => {
              return (<tr>
                <td>{bucket.currency}</td>
                <td>{Intl.NumberFormat('en-IN').format(bucket.totalStake)}</td>
                <td>{Intl.NumberFormat('en-IN').format(bucket.totalReturn.toFixed(2))}</td>
                <td>{Intl.NumberFormat('en-IN').format((bucket.totalStake - bucket.totalReturn).toFixed(2))}</td>
                <td>{Intl.NumberFormat('en-IN').format(bucket.doc_count)}</td>
              </tr>)
            })
          }
        </tbody>

      </table>
      <p>* Please scroll down to the end of the page to get the final consolidated numbers for the defined date range. </p>
      {gameRounds && (


        <InfiniteScroll
          dataLength={gameRounds.length}
          next={() => search(false)}
          hasMore={hasMore}
        >
          <table className="table table-bordered table-hover table-striped">

            <thead>
              <tr>
                <td>gameRoundID</td>
                <td>playerId</td>
                <td>gameId</td>
                <td>subPartnerID</td>
                <td>mode</td>
                <td>currency</td>
                <td>startDate</td>
                <td>endDate</td>
                <td>Stake</td>
                <td>Win</td>
                <td>GGR</td>
              </tr>
            </thead>
            <tbody>
              {gameRounds.map(
                ({
                  gameRoundId,
                  playerId,
                  gameId,
                  subpartner_id,
                  isPlayForFun,
                  currency,
                  tsCreated,
                  tsCompleted,
                  totalStake,
                  totalReturn,
                }) => (
                  <tr key={gameRoundId}>
                    <td>
                      <Link to={`/gameRoundHistory/${gameRoundId}`}>{gameRoundId}</Link> (
                      <Link to={`/log/${gameRoundId}`}>log</Link>)
                    </td>
                    <td>{playerId}</td>
                    <td>{gameId}</td>
                    <td>{subpartner_id ? subpartner_id : environmentName}</td>
                    <td>{isPlayForFun ? 'fun' : 'real'}</td>
                    <td>{currency}</td>
                    <td>{new Date(tsCreated).toLocaleString()}</td>
                    <td>{tsCompleted && new Date(tsCompleted).toLocaleString()}</td>
                    <td>{totalStake}</td>
                    <td>{totalReturn}</td>
                    <td>{totalStake - totalReturn}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </InfiniteScroll>
      )}
      <div>
        <h1 className='dashHeader'>Mini DashBoard <CSVLink filename={`Game Round History Report ${new Date().toLocaleString()}`} data={formatDataForCSV(gameRounds)}>Download Report</CSVLink></h1>
      </div>
      <table className="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <td>totalbet</td>
            <td>totalwin</td>
            <td>totalGGR</td>
            <td>totalGames</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{totalStake}</td>
            <td>{totalReturn}</td>
            <td>{totalGGR}</td>
            <td>{totalDocs}</td>
          </tr>
        </tbody>
      </table>
      <p style={{ textAlign: 'center' }}>
        <b>The End</b>
      </p>
    </>
  );
};

export default GameRounds;
