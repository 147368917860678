const getEnvironmentName = (url) => {
  if(window.location.hostname.split(".")[0] !== 'assets') {
    return window.location.hostname.split(".")[0];
  }
  else {
    let subUrl = url.slice(13, url.length-17)
    let environmentName;
    for(let i=0; i<subUrl.length; i++) {
      if(subUrl.charAt(i) === '-' || subUrl.charAt(i) === '.') {
        environmentName = subUrl.substr(i+1);
        return environmentName;
      }
    }
  }
}

export default getEnvironmentName;